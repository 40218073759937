import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import DefaultSeo from '../default-seo'
import LandingPageSeo from '../landing-page-seo'
import { Box } from '@mui/material'

function SEO(props) {
  return (
    <Switch>
      <Route exact path={['/quiz/concern', '/quiz/concern/']}>
        <DefaultSeo
          seoTitle="Personalized Skincare Quiz | PROVEN"
          seoDescription="Take the 3 min skin quiz developed by Stanford scientists. We'll identify the top ingredients and formulations personalized for your skin type and lifestyle. As seen on Shark Tank."
        />
      </Route>
      <Route exact path={['/personalized-subscription', '/personalized-subscription/']}>
        <DefaultSeo
          seoTitle="Personalized 3 Step Skincare Set | PROVEN"
          seoDescription="Our dermatologists analyze 47 factors about your skin, lifestyle and environment to create a simple 3 step skincare set tailored to you. Get your formula now."
        />
      </Route>
      <Route exact path={['/personalized-eye-cream-duo', '/personalized-eye-cream-duo/']}>
        <DefaultSeo
          seoTitle="Personalized Day and Night Eye Creams | PROVEN"
          seoDescription="Protect and rejuvenate your eyes with our personalized day and night eye creams, tailored to your skin, environment, and lifestyle. Get your formula now."
        />
      </Route>
      <Route exact path={['/personalized-face-cleanser', '/personalized-face-cleanser/']}>
        <DefaultSeo
          seoTitle="Personalized Facial Cleanser | PROVEN"
          seoDescription="Achieve the healthy skin you deserve with our personalized facial cleanser. A cleanser, toner, and exfoliator in one- made just for you! Get your formula now."
        />
      </Route>
      <Route exact path={['/personalized-day-moisturizer', '/personalized-day-moisturizer/']}>
        <DefaultSeo
          seoTitle="Personalized Day Moisturizer With SPF | Proven Skincare"
          seoDescription="Our personalized daily moisturizer with SPF helps prevent the skin from drying out, while targeting other issues you may have. Get your custom formula now."
        />
      </Route>
      <Route exact path={['/personalized-night-cream', '/personalized-night-cream/']}>
        <DefaultSeo
          seoTitle="Night Cream Made For You | Personalized Night Cream | PROVEN"
          seoDescription="Our personalized night cream restores elasticity and repairs, plumps and firms your skin while you get your beauty sleep. Get your custom formula now."
        />
      </Route>
      <Route>
        <DefaultSeo {...props} />
      </Route>
    </Switch>
  )
}

const PageWrapper = ({
  children,
  renderHelmet,
  background,
  backgroundColor,
  noindex,
  ...seoProps
}) => {
  const [onAppSubdomain, setOnAppSubdomain] = useState(false)
  const displayNoIndex = noindex || onAppSubdomain

  useEffect(() => {
    if (
      window &&
      (window.location.hostname.includes('app.provenskincare.com') ||
        window.location.hostname.includes('tryproven.com'))
    ) {
      setOnAppSubdomain(true)
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        backgroundColor: backgroundColor,
        background: background
      }}
    >
      {renderHelmet && <SEO {...seoProps} />}
      {displayNoIndex && <LandingPageSeo />}
      {children}
    </Box>
  )
}

PageWrapper.defaultProps = {
  renderHelmet: true,
  noindex: false
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  renderHelmet: PropTypes.bool,
  backgroundColor: PropTypes.string,
  //seoProps
  seoCanonicalUrl: PropTypes.string,
  seoUrl: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoStructuredDataArr: PropTypes.array,
  noindex: PropTypes.bool
}

export default PageWrapper

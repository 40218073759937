import React, { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './style.scss'
import propTypes from 'prop-types'

class ProvenButton extends Component {
  onClick = e => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    const {
      text,
      textSub,
      textSecond,
      textCrossed,
      textColor,
      textUnderline,
      textTransformNone,
      textWhileLoading,
      loading,
      black,
      blackStroke,
      whiteStroke,
      transparent,
      highlightedOnHover,
      white,
      green,
      gradientGreen,
      greenBorder,
      greyBorder,
      sm,
      lg,
      background,
      disabled,
      width,
      noLeftPadding,
      noRightPadding,
      style,
      link,
      lowCaseStyle,
      renderContent, // use this function to render a completely custom button content (e.g. icons)
      ...props
    } = this.props

    const classes =
      `button` +
      ` ${loading ? 'loading' : ''} ` +
      ` ${white ? 'white-back' : ''} ` +
      ` ${noLeftPadding ? 'no-left-padding' : ''} ` +
      ` ${noRightPadding ? 'no-right-padding' : ''} ` +
      ` ${black ? 'black-back' : ''} ` +
      ` ${transparent ? 'transparent-back' : ''} ` +
      ` ${highlightedOnHover ? 'highlighted-on-hover' : ''} ` +
      ` ${blackStroke ? 'black-stroke' : ''} ` +
      ` ${whiteStroke ? 'white-stroke' : ''} ` +
      ` ${green ? 'green-back' : ''} ` +
      ` ${gradientGreen ? 'green-gradient-back' : ''} ` +
      ` ${background ? 'custom-back' : ''} ` +
      ` ${link ? 'link-style' : ''} ` +
      ` ${lowCaseStyle ? 'low-case-style' : ''} ` +
      ` ${textUnderline ? 'text-underline' : ''} ` +
      ` ${greenBorder ? 'green-border' : ''} ` +
      ` ${greyBorder ? 'grey-border' : ''} ` +
      ` ${sm ? 'sm' : ''} ` +
      ` ${lg ? 'lg' : ''} ` +
      ` ${textSecond ? 'text-second' : ''} ` +
      ` ${textTransformNone ? 'text-transform-none' : ''} `
    return (
      <button
        {...props}
        styleName={classes}
        style={{
          background,
          color: textColor,
          /* Everywhere in CSS we control the width of the buttons with 'min-width' property only.
             This is done for buttons to stay flexible and be able to accommodate any text length.
             So here, when we need a custom width, we overwrite the 'min-width' prop only: */
          minWidth: width,
          ...style
        }}
        onClick={this.onClick}
        disabled={disabled || loading}
        aria-label={text}
      >
        {!loading ? (
          <div styleName="content">
            {renderContent ? (
              <>{renderContent()}</>
            ) : (
              <>
                <div styleName="main">
                  <span styleName="text">
                    {text}
                    {textSub && <span styleName="text-sub"> {textSub}</span>}
                  </span>
                </div>
                {textSecond && (
                  <div styleName={`secondary ${green ? 'green' : ''}`}>
                    {textCrossed && <span styleName="text-crossed">{textCrossed}</span>}
                    <span styleName="text-second">{textSecond}</span>
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div styleName="content-loading">
            <CircularProgress color="inherit" size={17} />
            <span styleName="text">{textWhileLoading}</span>
          </div>
        )}
      </button>
    )
  }
}

ProvenButton.propTypes = {
  text: propTypes.string,
  textSub: propTypes.string,
  textSecond: propTypes.string,
  textColor: propTypes.string,
  textCrossed: propTypes.string,
  textTransformNone: propTypes.bool,
  textWhileLoading: propTypes.string,
  loading: propTypes.bool,
  black: propTypes.bool,
  blackStroke: propTypes.bool,
  white: propTypes.bool,
  whiteStroke: propTypes.bool,
  gradientGreen: propTypes.bool,
  highlightedOnHover: propTypes.bool,
  noRightPadding: propTypes.bool,
  noLeftPadding: propTypes.bool,
  background: propTypes.string,
  greenBorder: propTypes.bool,
  greyBorder: propTypes.bool,
  sm: propTypes.bool,
  lg: propTypes.bool,
  width: propTypes.string,
  disabled: propTypes.bool,
  renderContent: propTypes.func,
  link: propTypes.bool,
  lowCaseStyle: propTypes.bool
}

ProvenButton.defaultProps = {
  disabled: false,
  loading: false,
  text: 'Submit',
  textWhileLoading: 'Submitting',
  sm: false,
  lg: false
}

export default ProvenButton

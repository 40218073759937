import React, { useState } from 'react'
import { Stack, Box, Typography, Button } from '@mui/material'
import useCurrency from '../../../../hooks/useCurrency'
import { formatPrice } from '../../../../utils/helpers'
import { createProvenTheme } from '../../../../styles/theme-proven'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

function Accessory({
  onAddOnce,
  onViewDetails,
  title,
  price,
  imageUrl,
  onHoverImageUrl,
  badge,
  sx = {}
}) {
  const { currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()
  const [onHover, setOnHover] = useState()

  const toggleHover = newState => {
    if (newState !== onHover) setOnHover(newState)
  }

  return (
    <Stack
      position="relative"
      gap={1}
      justifyContent="flex-start"
      alignItems="center"
      sx={{ ...sx, backgroundColor: themeProven.palette.gray.beige, pb: 3, mx: { xs: -2, lg: 0 } }}
    >
      {badge && (
        <Box position="absolute" sx={{ top: spacing(3), left: spacing(3) }} zIndex={2}>
          {badge}
        </Box>
      )}
      <Box
        pt={3}
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        onClick={onViewDetails}
      >
        <Box sx={{ position: 'relative', width: spacing(24.25), height: spacing(21.5) }}>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 'auto',
              left: 'auto',
              opacity: onHover ? 0 : 1
            }}
            component="img"
            src={imageUrl}
            width={{ xs: '100%', md: spacing(25.5) }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 'auto',
              left: 'auto',
              opacity: onHover ? 1 : 0
            }}
            component="img"
            src={onHoverImageUrl}
            width={{ xs: '100%', md: spacing(25.5) }}
          />
        </Box>
        {onHover && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              width: '100%',
              height: '40px',
              background: '#323232',
              opacity: 0.5,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '0px 0px 4px 4px'
            }}
            onClick={onViewDetails}
          >
            View Details
          </Box>
        )}
      </Box>
      <Typography
        mt={2}
        height={spacing(6.75)}
        variant="title2"
        maxWidth={spacing(30)}
        color="gray.elysian"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography mb={2} variant="title1" color="primary.main">
        {currencySymbol}
        {formatPrice(price)}
        {currencyToDisplayOrEmptyIfUS}
      </Typography>
      <Button variant="outlined" color="primary" onClick={onAddOnce}>
        ADD ONCE
      </Button>
    </Stack>
  )
}
export default Accessory

import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ProvenButton from '../proven-button'
import { useIntentContext } from '../../../providers/IntentAndFunnelsProvider'
import useActiveCampaign from 'hooks/use-active-campaign'
import propTypes from 'prop-types'
import Button from '@mui/material/Button'
import { trackEvent } from '../../../utils/analytics'

const ProvenButtonWithIntent = props => {
  const {
    trackEventAction,
    intent,
    text,
    variant,
    color,
    fullWidth = false,
    sx,
    gift = false
  } = props

  const location = useLocation()
  const history = useHistory()
  const campaign = useActiveCampaign()
  const { getTargetRoute } = useIntentContext()

  const handleCTAClick = e => {
    const { onClick } = props
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    trackEvent('homepage_cta', { CTA: text, variant: variant })
    if (trackEventAction) trackEventAction()
    if (onClick) onClick()

    if (gift || text === 'Gift Proven') {
      const anchorTarget = document.getElementById('giftCertificatesSection')
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    const nextRoute = getTargetRoute(intent)
    if (campaign?.promoCode) {
      let promoCode = location.pathname.startsWith('/personalized-eye-cream-duo')
        ? 'EYEDUO'
        : campaign.promoCode
      history.push(`${nextRoute}?promo=${promoCode}`)
    } else {
      history.push(nextRoute)
    }
  }

  // TODO remove ProvenButton once we migrate all pages that uses ProvenButtonWithIntent to Mui/Branding
  return variant ? (
    <Button variant={variant} onClick={handleCTAClick} color={color} fullWidth={fullWidth} sx={sx}>
      {text}
    </Button>
  ) : (
    <ProvenButton {...props} onClick={handleCTAClick} />
  )
}

ProvenButtonWithIntent.propTypes = {
  trackEventAction: propTypes.func,
  intent: propTypes.oneOf(['eye', 'skin']),
  fullWidth: propTypes.bool,
  gift: propTypes.bool,
  variant: propTypes.string,
  sx: propTypes.object,
  text: propTypes.string,
  color: propTypes.string
}

export default ProvenButtonWithIntent

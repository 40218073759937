import React, { useState } from 'react'
import ProvenDialog from '../../../shared/proven-dialog'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { createProvenTheme } from '../../../../styles/theme-proven'
import propTypes from 'prop-types'
import { calcShippingRangeFromBillingDate } from '../../../../utils/helpers'
import moment from 'moment'
import ProvenIcon from '../../proven-icon'

const theme = createProvenTheme()

function AddToSubscriptionConfirmation({
  currentSubscription,
  onConfirmation,
  onDiscard,
  triggeringButtonRender
}) {
  const { shippingStartMs, shippingEndMs } = calcShippingRangeFromBillingDate(
    currentSubscription?.nextBillingAt
  )

  let text = ''
  if (shippingEndMs && shippingStartMs) {
    text = `Shipping: ${moment(shippingStartMs).format('MMM D')} - ${moment(shippingEndMs).format(
      'MMM D, YYYY'
    )}`
  } else {
    console.error(
      `Invalid calcShippingRangeFromBillingDate result : shippingEndMs=${shippingEndMs} && shippingStartMs=${shippingStartMs}`
    )
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    if (onDiscard) onDiscard()
  }
  const handleConfirmation = () => {
    setOpen(false)
    onConfirmation()
  }

  return (
    <>
      {triggeringButtonRender(handleOpen)}
      <ProvenDialog
        open={open}
        onClose={() => setOpen(false)}
        showCloseButton
        customCloseIcon={
          <ProvenIcon
            sx={{ mt: 2.5, mr: 2.5 }}
            size="medium"
            type="system"
            name="cross-close"
            color="gray.elysian"
          />
        }
        sx={{
          mx: { xs: `${theme.spacing(2)} !important`, md: 'unset !important' },
          maxWidth: '439px !important'
        }}
      >
        <Stack
          sx={{
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.gray.beige
          }}
          alignItems="flex-start"
          gap={2}
          p={4}
        >
          <Typography variant="h4" color="gray.elysian">
            Add to your active
            <br />
            System Subscription?
          </Typography>

          <Typography variant="body2" color="gray.black">
            {text}
          </Typography>

          <Stack sx={{ mt: 2 }} direction="row" justifyContent="space-between" width="100%" gap={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{
                zIndex: 2000
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConfirmation}
              sx={{
                zIndex: 2000
              }}
            >
              CONFIRM TO ADD
            </Button>
          </Stack>
        </Stack>
      </ProvenDialog>
    </>
  )
}
AddToSubscriptionConfirmation.propTypes = {
  currentSubscription: propTypes.object.isRequired,
  onConfirmation: propTypes.func.isRequired,
  onDiscard: propTypes.func,
  triggeringButtonRender: propTypes.func.isRequired
}

export default AddToSubscriptionConfirmation

import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography, Stack, Box, useMediaQuery } from '@mui/material'
import propTypes from 'prop-types'
import ProvenButtonWithIntent from '../proven-button-with-intent'
import useAuth from 'hooks/use-auth'

import {
  calcNonDuoProductsTotalPrice,
  calcNonSystemProductsTotalPrice,
  calcSerumProductsTotalPrice,
  formatPrice,
  lookForActiveSubsWhereIndividualProductCanBeAdded
} from '../../../utils/helpers'
import useCurrency from '../../../hooks/useCurrency'
import { usePrice, DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID } from '../../../hooks/usePrice'
import {
  CLEANSER_PRODUCT,
  COMBO_PRODUCTS,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  PRODUCT_ID_BY_INTENT,
  SERUM_PRODUCT,
  SYSTEM_PRODUCT
} from '../../../constants/products'
import {
  activeSubscriptionsSelect,
  combinedProductsSelector,
  isNextActionOnCurrentProductPageOneTimeSelector
} from '../../../utils/selectors'
import useSubscriptionModification from '../../../hooks/useSubscriptionModification'
import AddToSubscriptionConfirmation from './add-to-subscription-confirmation'
import SavingLegend from './saving-legend'
import { createProvenTheme } from '../../../styles/theme-proven'
import useCartAction from '../../../hooks/useCartAction'
import { useContext } from 'react'
import { IntentAndFunnelsContext } from '../../../providers/IntentAndFunnelsProvider'
import { KEYWORD_SKIN } from '../../../constants/funnels'
import usePriceWithDiscounts from '../../../hooks/usePriceWithDiscounts'
import { subscriptionFrequencyToMonths } from '../../../utils/helpers/subscription'
import {
  SAVING_LEGEND_EMPTY_STYLE,
  SAVING_LEGEND_FULL_STYLE,
  SAVING_LEGEND_SMALL_STYLE
} from '../../../constants/constants'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

const ProvenButtonAddToCart = ({
  textToShowForAuthenticated,
  textToShowForUnauthenticated,
  textToShowForAddingToSubscription,
  productId,
  forceSubscriptionAction,
  forceOneTimeAction,
  addPriceWhenIsAuthenticated,
  savingCancellingAndShippingLegendStyle = SAVING_LEGEND_EMPTY_STYLE,
  variant = 'contained',
  color = 'secondary',
  size = 'medium',
  'data-cy': dataCy,
  intent: enforcedIntent,
  fullWidth = false,
  sx = {},
  showPrice = true
}) => {
  const { isAuthenticated } = useAuth()
  const { intent: currentContextIntent } = useContext(IntentAndFunnelsContext)
  const { currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()
  const { getPriceAfterDiscount } = usePriceWithDiscounts()
  const activeSubscriptions = useSelector(activeSubscriptionsSelect)
  const isOneTimeSelected = useSelector(isNextActionOnCurrentProductPageOneTimeSelector)

  const isSubscriptionAction =
    (!!forceSubscriptionAction && !forceOneTimeAction) || //if forceSubscriptionAction, action will be subscription no matter next conditions
    (!(!!forceOneTimeAction && !forceSubscriptionAction) && //if forceOneTimeAction, action will be one-time no matter next conditions
      !isOneTimeSelected) //if neither forceSubscriptionAction nor forceOneTimeAction is in true, action will be based on isOneTimeSelected value.
  //if forceSubscriptionAction and forceOneTimeAction are both in true, action will also be based on isOneTimeSelected value, since it is not a valid case

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const { addProductToCart } = useCartAction()
  const products = useSelector(combinedProductsSelector)
  const { addProductToNextSubscription } = useSubscriptionModification()
  const getPriceData = usePrice()

  const comboProductPriceWithoutDiscountCalculatorByProductId = {
    [SYSTEM_PRODUCT]: calcNonSystemProductsTotalPrice.bind(this, { products }),
    [EYE_CREAM_PRODUCT]: calcNonDuoProductsTotalPrice.bind(this, { products }),
    [SERUM_PRODUCT]: calcSerumProductsTotalPrice.bind(this, { products })
  }

  const handleAddToCart = (priceData, ctaText) => {
    addProductToCart({
      productId: productIdSelectedOrBasedOnIntent,
      ctaText,
      oneTime: !isSubscriptionAction
    })
  }
  const handleAddToSubscription = (activeSubscription, productPriceData) => e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    addProductToNextSubscription(activeSubscription, productPriceData)
  }

  // If not authenticated, send the user to the quiz.
  if (!isAuthenticated)
    return (
      <ProvenButtonWithIntent
        variant={variant}
        color={color}
        text={textToShowForUnauthenticated}
        intent={enforcedIntent}
        data-cy={dataCy}
        fullWidth={fullWidth}
        sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
      />
    )

  const getProductIdBasedOnIntent = () => {
    const intent = enforcedIntent || currentContextIntent || KEYWORD_SKIN
    if (!enforcedIntent && !currentContextIntent) {
      console.warn(
        `in ProvenButtonAddToCart, there is neither a productId nor a enforcedIntent nor a context intent. it'll be used ${KEYWORD_SKIN} as default to get the productId. It may be a temporary state between renders or a desired behavior`
      )
    }
    return PRODUCT_ID_BY_INTENT[intent]
  }

  const productIdSelectedOrBasedOnIntent = productId || getProductIdBasedOnIntent()
  if (!productIdSelectedOrBasedOnIntent) {
    console.warn(
      'ProvenButtonAddToCart for authenticated users requires a valid productIdSelectedOrBasedOnIntent, in order to know which product will be added into the cart.' +
        ' actual productIdSelectedOrBasedOnIntent:' +
        productIdSelectedOrBasedOnIntent
    )
    return null
  }

  // If authenticated, add the selected product to cart or to current subscription.
  const getButtonText = ({ fullPriceInCents, defaultPriceInCents, isAddingToSubscription }) => {
    if (isAddingToSubscription || !addPriceWhenIsAuthenticated)
      return isAddingToSubscription && textToShowForAddingToSubscription
        ? textToShowForAddingToSubscription
        : textToShowForAuthenticated

    const { displayPrice, oldPriceFormatted } = getPriceAfterDiscount({
      productId: productIdSelectedOrBasedOnIntent,
      fullPriceFormatted: fullPriceInCents && formatPrice(fullPriceInCents),
      priceBeforeDiscountFormatted: formatPrice(defaultPriceInCents),
      showOneTimePrice: !isSubscriptionAction
    })

    return (
      <Box component="p" width="max-content">
        <Typography component="span" variant="contained" color="gray.elysian">
          {showPrice
            ? `${textToShowForAuthenticated} - ${currencySymbol}${displayPrice}${currencyToDisplayOrEmptyIfUS}`
            : `${textToShowForAuthenticated}`}
        </Typography>
        {showPrice && oldPriceFormatted && (
          <>
            <Typography component="span" variant="footnote" color="gray.elysian">
              &nbsp;
            </Typography>
            <Typography
              component="s"
              variant="footnote"
            >{`${currencySymbol}${oldPriceFormatted}`}</Typography>
          </>
        )}
      </Box>
    )
  }

  //TODO extract this function to a hook, maybe add it to usePrice hook
  const getPricesWithAndWithoutDiscountInCents = priceData => {
    const defaultPriceInCents = priceData.price
    const priceWithoutDiscountCalculator =
      comboProductPriceWithoutDiscountCalculatorByProductId[productIdSelectedOrBasedOnIntent]
    const fullPriceInCents = priceWithoutDiscountCalculator
      ? priceWithoutDiscountCalculator()
      : null

    const discountAmountInCents = fullPriceInCents ? fullPriceInCents - defaultPriceInCents : 0
    return { defaultPriceInCents, fullPriceInCents, discountAmountInCents }
  }
  const renderAddToCartButton = priceData => {
    const { defaultPriceInCents, fullPriceInCents, discountAmountInCents } =
      getPricesWithAndWithoutDiscountInCents(priceData)

    const button = (
      <Button
        size={size}
        variant={variant}
        color={color}
        onClick={e => {
          if (e) {
            e.preventDefault()
            e.stopPropagation()
          }

          if (textToShowForAuthenticated === 'Gift Proven') {
            const anchorTarget = document.getElementById('giftCertificatesSection')
            anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
            return
          }
          handleAddToCart(priceData, textToShowForAuthenticated)
        }}
        data-cy={dataCy}
        fullWidth={fullWidth}
        sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
      >
        {getButtonText({ defaultPriceInCents, fullPriceInCents })}
      </Button>
    )
    const frequencyInMonths = subscriptionFrequencyToMonths({
      frequency: priceData.period,
      frequencyUnit: priceData.period_unit
    })
    return savingCancellingAndShippingLegendStyle !== SAVING_LEGEND_EMPTY_STYLE &&
      discountAmountInCents ? (
      <Stack gap={2} pb={{ md: 3, lg: 0 }} position="relative">
        {button}
        <SavingLegend
          sx={fullWidth ? { alignItems: 'center' } : {}}
          frequency={frequencyInMonths}
          frequencyUnit={'month'}
          showReformulationLegend={
            !isSubscriptionAction ||
            savingCancellingAndShippingLegendStyle === SAVING_LEGEND_SMALL_STYLE
          }
          showCancelLegend={savingCancellingAndShippingLegendStyle === SAVING_LEGEND_FULL_STYLE}
          showOnlyStaticLegend={
            savingCancellingAndShippingLegendStyle === SAVING_LEGEND_SMALL_STYLE
          }
          isOneTime={!isSubscriptionAction}
          savingAmount={discountAmountInCents}
        />
      </Stack>
    ) : (
      button
    )
  }

  const renderAddToCurrentSubscriptionButton = (currentSubscription, priceData) => {
    const { fullPriceInCents, defaultPriceInCents } =
      getPricesWithAndWithoutDiscountInCents(priceData)

    return (
      <AddToSubscriptionConfirmation
        currentSubscription={currentSubscription}
        onConfirmation={handleAddToSubscription(currentSubscription, priceData)}
        triggeringButtonRender={openPopup => (
          <Button
            size={size}
            variant={variant}
            color={color}
            data-cy={dataCy}
            fullWidth={fullWidth}
            onClick={e => {
              if (textToShowForAuthenticated === 'Gift Proven') {
                const anchorTarget = document.getElementById('giftCertificatesSection')
                anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
                return
              }
              openPopup(e)
            }}
            sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
          >
            {getButtonText({ defaultPriceInCents, fullPriceInCents, isAddingToSubscription: true })}
          </Button>
        )}
      />
    )
  }
  if (isSubscriptionAction && !COMBO_PRODUCTS.includes(productIdSelectedOrBasedOnIntent)) {
    // is subscription selected and product is an individual one
    const activeSubscription = lookForActiveSubsWhereIndividualProductCanBeAdded(
      productIdSelectedOrBasedOnIntent,
      activeSubscriptions
    )
    if (activeSubscription) {
      // and there is a subscription where it can be added, so product will be added to current subscription
      const priceQuery = {
        ...DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID[productIdSelectedOrBasedOnIntent],
        productId: productIdSelectedOrBasedOnIntent
      }
      if (activeSubscription?.frequency) priceQuery.period = activeSubscription.frequency
      if (activeSubscription?.frequencyUnit)
        priceQuery.periodUnit = activeSubscription?.frequencyUnit

      return renderAddToCurrentSubscriptionButton(
        activeSubscription,
        getPriceData({
          strategy: 'period',
          query: priceQuery
        })
      )
    }
  }

  // product is a combo (System or EyeCreamDuo) or it's one-time selected or it's an individual product but there is not active subscription to add it
  // then it renders add-to-cart button
  const addToCartProductPrice = getPriceData({
    strategy: 'period',
    query: isSubscriptionAction
      ? {
          ...DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID[productIdSelectedOrBasedOnIntent],
          productId: productIdSelectedOrBasedOnIntent
        }
      : { periodicity: 'one-time', productId: productIdSelectedOrBasedOnIntent }
  })
  return renderAddToCartButton(addToCartProductPrice)
}

ProvenButtonAddToCart.propTypes = {
  textToShowForAuthenticated: propTypes.string,
  textToShowForUnauthenticated: propTypes.string,
  addPriceWhenIsAuthenticated: propTypes.bool,
  savingCancellingAndShippingLegendStyle: propTypes.string,
  forceSubscriptionAction: propTypes.bool,
  intent: propTypes.string,
  productId: propTypes.oneOf([
    [
      SYSTEM_PRODUCT,
      DAY_MOISTURIZER_PRODUCT,
      NIGHT_CREAM_PRODUCT,
      CLEANSER_PRODUCT,
      EYE_CREAM_PRODUCT,
      NIGHT_EYE_CREAM_PRODUCT,
      DAY_EYE_CREAM_PRODUCT,
      SERUM_PRODUCT
    ]
  ]),
  variant: propTypes.string,
  fullWidth: propTypes.bool,
  size: propTypes.string,
  color: propTypes.string,
  showPrice: propTypes.bool
}

export default ProvenButtonAddToCart

import React from 'react'
import propTypes from 'prop-types'
import { Box, Button, Divider, Grid, IconButton, SwipeableDrawer, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

//TODO unify with ingredients-list-drawer
function FullIngredientsListDrawer({ open, individualProductsWithFullIngredients, handleClose }) {
  return (
    <SwipeableDrawer anchor="right" open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'relative',
          px: { xs: 2, md: 4 },
          py: 6,
          maxWidth: { xs: '100%', md: '435px' }
        }}
      >
        <IconButton sx={{ position: 'absolute', top: 32, right: 24 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" color="gray.elysian" sx={{ mb: 3 }}>
          Full Ingredients List
        </Typography>

        {individualProductsWithFullIngredients?.map(individualProduct => (
          <React.Fragment key={`full-ing-title-${individualProduct.title}`}>
            <Typography variant="title2" color="gray.elysian" component="div" sx={{ mb: 1 }}>
              {`${individualProduct.title} Full ingredients list:`}
            </Typography>
            <Box sx={{ mb: { xs: 1, md: 5 } }}>
              {individualProduct.fullIngredients && (
                <Typography variant="body2" color="gray.blueGray">
                  {individualProduct.fullIngredients.join(', ')}
                </Typography>
              )}
            </Box>
          </React.Fragment>
        ))}

        <Divider
          sx={{
            display: { xs: 'block', md: 'none' },
            mb: 5,
            opacity: 0.2,
            mx: -2
          }}
        />
        <Box sx={{ textAlign: 'right', width: '100%' }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

FullIngredientsListDrawer.propTypes = {
  open: propTypes.bool.isRequired,
  individualProductsWithFullIngredients: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fullIngredients: PropTypes.array.isRequired
    })
  ).isRequired,
  handleClose: propTypes.func.isRequired
}

export default FullIngredientsListDrawer

import React from 'react'
import ProductIngredients from './product-ingredients'

const DuoIngredients = ({ tags, ingredients, ...rest }) => {
  if (!tags || !ingredients) {
    return
  }

  return <ProductIngredients tags={tags} ingredients={ingredients} {...rest} />
}

export default DuoIngredients

import React from 'react'
import ProvenIconWithText from '../proven-icon-with-text'
import { Typography } from '@mui/material'

function ProvenErrorMessage({ message }) {
  return (
    <ProvenIconWithText name="alert" type="system" gap={1} iconSize="small" color="error.main">
      <Typography variant="footnote" color="error.main">
        {message}
      </Typography>
    </ProvenIconWithText>
  )
}
export default ProvenErrorMessage

//use this file version in case of conflicts (ENG-1608)
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toLower } from 'lodash'
import { CDN_IMG } from 'constants/endpoints'
import useActiveCampaign from 'hooks/use-active-campaign'
import { addItemToCart, openCart } from '../../../actions/cart.actions'
import { appEditionSelector } from '../../../utils/selectors'
import { APP_EDITION_SEPHORA } from '../../../constants/constants'
import { forceGetContentV2 } from '../../../services/builder-helper'
import { Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import Accessory from './accessory'

const themeProven = createProvenTheme()

const SHOP_PRODUCT_BESTSELLER_LABEL = 'bestseller'
const SHOP_PRODUCT_NEW_LABEL = 'new'

function Badge({ defaultBadge }) {
  const cmBadgeSx = {
    background: themeProven.palette.secondary.main,
    color: themeProven.palette.gray.black
  }
  const bestSellerBadgeSx = {
    background: themeProven.palette.secondary.main,
    color: themeProven.palette.gray.elysian,
    textTransform: 'uppercase'
  }
  const newBadgeSx = {
    background: themeProven.palette.primary.main,
    color: themeProven.palette.gray.white
  }
  let text = defaultBadge
  let badgeSx
  const activeCampaign = useActiveCampaign()
  const isCm = ['cybermonday'].includes(activeCampaign?.id)
  if (isCm) {
    text = '30% Off Limited-Time'
    badgeSx = cmBadgeSx
  } else if (defaultBadge && defaultBadge.toLowerCase() === SHOP_PRODUCT_BESTSELLER_LABEL) {
    badgeSx = bestSellerBadgeSx
  } else if (defaultBadge && defaultBadge.toLowerCase() === SHOP_PRODUCT_NEW_LABEL) {
    badgeSx = newBadgeSx
  } else {
    return null
  }

  return (
    <Box sx={{ ...badgeSx, borderRadius: '4px', px: 1, py: 0.25 }}>
      <Typography variant="title1" color="inherit">
        {text}
      </Typography>
    </Box>
  )
}

function OneTimeAddons({ title, paragraph, prefix_url }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const appEdition = useSelector(appEditionSelector)
  const cartItems = useSelector(state => state?.cart?.items)
  const shopProducts = useSelector(state => state.shop.shopProducts)
  const productsList = shopProducts.filter(
    product =>
      product.metadata &&
      product.metadata.isAccessory &&
      !product.metadata.isNotForSale &&
      product.id != 'cryo-roller-v2'
  )

  const [shopProductById, setShopProductById] = useState({})

  const isSephora = appEdition === APP_EDITION_SEPHORA
  const showAddons = !isSephora

  const isEmptyLabelValue = label => !label || label === '-'

  const fetchShopProductsFromCms = async () => {
    const productsListRetrieved = await forceGetContentV2('shop-products', {})

    if (productsListRetrieved) {
      const strapiObj = productsListRetrieved.reduce((acc, item) => {
        const prod = item.data
        acc[prod.productId] = prod
        return acc
      }, {})

      setShopProductById({ ...strapiObj })
    }
  }

  useEffect(() => {
    fetchShopProductsFromCms()
  }, [])

  /**
   * add item into cart
   * @param priceItem
   */
  const handleAddItemToCart = async priceItem => {
    const ctaText = 'Add Once'

    if (!priceItem) return

    let qty = 1
    if (cartItems[priceItem.id]) {
      qty = cartItems[priceItem.id].qty + 1
    }
    dispatch(addItemToCart({ ctaText, priceItem, qty, shouldCallPreview: false }))
    dispatch(openCart())
  }

  const handleClickProduct = productId => {
    history.push(`${prefix_url}/${toLower(productId)}`)
  }

  const onButtonClick = (e, product) => {
    e.preventDefault()
    e.stopPropagation()

    handleAddItemToCart(product)
  }

  if (!showAddons) {
    return null
  }

  return (
    <ThemeProvider theme={themeProven}>
      <Box sx={{ px: 0, mt: { xs: 2.5, md: 7.5 } }}>
        <Typography variant="h3" color="gray.black" sx={{ mb: 1, textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body2" color="gray.black" sx={{ mb: 5, textAlign: 'center' }}>
          {paragraph}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-start' },
            width: '100%',
            flexWrap: 'wrap',
            columnGap: 6.125,
            rowGap: 2,
            mx: 'auto'
          }}
        >
          {productsList.map(accessory => {
            const defaultBadge = shopProductById[toLower(accessory.id)]?.label
            return (
              <Accessory
                key={`accessory-${accessory.id}`}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '50%',
                    md: '50%',
                    lg: 'calc(86% / 3)',
                    xl: '21%',
                    xxl: '17%'
                  },
                  maxWidth: '242px'
                }}
                price={accessory.one_time_price?.price}
                badge={
                  isEmptyLabelValue(defaultBadge) ? null : <Badge defaultBadge={defaultBadge} />
                }
                imageUrl={`${CDN_IMG + accessory.metadata.imageShopDesktop}`}
                onHoverImageUrl={`${CDN_IMG + accessory.metadata.imageShopDesktopHover}`}
                title={accessory?.metadata?.title ? accessory.metadata.title : accessory.name}
                onAddOnce={e => onButtonClick(e, accessory.one_time_price)}
                onViewDetails={() =>
                  handleClickProduct(
                    accessory?.metadata?.slug ? accessory.metadata.slug : accessory.id
                  )
                }
              />
            )
          })}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default OneTimeAddons
